<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <div>
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="交易类型">
                        <el-select placeholder="全部" v-model="queryParams.ty" clearable @change="changeQuery">
                            <el-option label="支出" value="0"></el-option>
                            <el-option label="收入" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="交易日期">
                        <el-date-picker type="daterange" @change="changeQuery" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="queryParams.time" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select placeholder="全部" v-model="queryParams.state" clearable @change="changeQuery">
                            <el-option label="下线" value="1"></el-option>
                            <el-option label="上线" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="exportExcel">Excel导出</el-button>
                    </el-form-item>
                    <el-form-item style="float: right">
                        <el-button type="primary" @click="changeQuery()">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="dataList">
                <el-table :data="pageInfo.list" style="width: 100%" v-loading="loading" element-loading-text="数据加载中" stripe border ref="multipleTable">
                    <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                    <el-table-column prop="uid" label="用户id" width="100" align="center"></el-table-column>
                    <el-table-column prop="ordersn" label="订单号" width="200" align="center"></el-table-column>
                    <el-table-column prop="pay_number" label="支付单号" width="240" align="center"></el-table-column>
                    <el-table-column prop="pay_time" label="支付时间" width="200" align="center"></el-table-column>
                    <el-table-column prop="price" label="价格" width="130" align="center"></el-table-column>
                    <el-table-column prop="username" label="用户名" width="130" align="center"></el-table-column>
                    <el-table-column prop="phone" label="手机号" width="130" align="center"></el-table-column>
                    <el-table-column prop="grade_name" label="用户等级" width="130" align="center"></el-table-column>

                    <el-table-column prop="resource" label="来源模块" width="140" align="center"></el-table-column>
                    <el-table-column prop="ty" label="交易类型" align="center" width="100">
                        <template slot-scope="scope">
                            <el-tag type="primary" v-if="scope.row.ty == 1">收入</el-tag>
                            <el-tag type="success" v-if="scope.row.ty == 0">支出</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_type" label="支付方式" align="center" width="130" :formatter="payTypeF">
                    </el-table-column>
                    <el-table-column prop="content" label="交易内容" align="center" width="200"></el-table-column>
                    <el-table-column prop="state" label="状态" align="center" fixed="right">
                        <template slot-scope="scope">
                            <el-tag type="primary" v-if="scope.row.state === 1">下线</el-tag>
                            <el-tag type="success" v-if="scope.row.state === 2">上线</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import Url from '@/components/Url';
import { request } from '@/common/request';
import { deal } from '@/common/main';
require("@/utils/Export2Excel")
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            limitUpload: 1,
            menuList: ['资金', '交易明细'],
            file: '',
            pageInfo: {},
            queryParams: {
                page: 1,
                pagesize: 10
            }
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        handleChange(file, fileList) {
            if (file.raw) {
                if ((file.raw.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.raw.type == 'application/vnd.ms-excel')) {
                    // this.importfxx(this.fileTemp)
                    this.file = file.raw

                } else {
                    this.$message({
                        type: 'warning',
                        message: '附件格式错误，请删除后重新上传！'
                    })
                }
            } else {
                this.$message({
                    type: 'warning',
                    message: '请上传附件！'
                })
            }
        },

        // handleRemove(file, fileList) {
        //     this.fileTemp = null
        // },
        importfxx() {
            let _this = this;
            // 通过DOM取文件数据
            // this.file = obj
            var rABS = false; //是否将文件读取为二进制字符串
            var f = this.file;
            var reader = new FileReader();
            //if (!FileReader.prototype.readAsBinaryString) {
            FileReader.prototype.readAsBinaryString = function (f) {
                var binary = "";
                var rABS = false; //是否将文件读取为二进制字符串
                var pt = this;
                var wb; //读取完成的数据
                var outdata;
                var reader = new FileReader();
                reader.onload = function (e) {
                    var bytes = new Uint8Array(reader.result);
                    var length = bytes.byteLength;
                    for (var i = 0; i < length; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    var XLSX = require('xlsx');
                    if (rABS) {
                        wb = XLSX.read(btoa(fixdata(binary)), { //手动转化
                            type: 'base64'
                        });
                    } else {
                        wb = XLSX.read(binary, {
                            type: 'binary'
                        });
                    }
                    outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);//outdata就是你想要的东西
                    let arr = []
                    outdata.map(v => {
                        let obj = {}
                        obj.ordersn = v['订单号']
                        obj.pay_number = v['支付单号']
                        obj.pay_time = v['交易时间']
                        obj.surplus_price = v['交易金额']
                        obj.pay_state = v['交易类型']
                        obj.content = v['交易内容']
                        arr.push(obj)
                    })
                    _this.pageInfo.list = [..._this.pageInfo.list, ...arr]
                    return arr
                }
                reader.readAsArrayBuffer(f);
            }

            if (rABS) {
                reader.readAsArrayBuffer(f);
            } else {
                reader.readAsBinaryString(f);
            }
        },
        // 导出
        exportExcel() {
            require.ensure([], () => {
                const { export_json_to_excel } = require("@/utils/Export2Excel");

                const tHeader = ["订单号", "支付单号", "交易时间", "交易金额", "交易类型", "交易内容"];// 上面设置Excel的表格第一行的标题

                const filterVal = ["ordersn", "pay_number", "pay_time", "surplus_price", "pay_state", "content"]; // 上面的index、nickName、name是tableData里对象的属性

                const list = this.pageInfo.list;              //把data里的tableData存到list

                const data = this.formatJson(filterVal, list);

                export_json_to_excel(tHeader, data, "列表excel");   //标题，数据，文件名
            });
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => v[j]));
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        loadData() {
            this.loading = true;
            if (this.queryParams.time) {
                this.queryParams.etime = deal.timestamp(this.queryParams.time[1]);
                this.queryParams.ctime = deal.timestamp(this.queryParams.time[0]);
                delete this.queryParams.time
            }
            request.get('/wallet/consumption/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list.map(function (val) {
                        val.pay_time = deal.timestamp(val.pay_time, 'unix');
                        val.pay_state = val.pay_state == 0 ? '支出' : '收入';
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        payTypeF(row) {
            return deal.payTypeFormat(row)
        }
    }
};
</script>

<style lang="scss" scoped>
.dataList {
    margin-top: 20px;
}
</style>